import { tassign } from 'tassign';
import {
  FETCH_USER_SUCCESS,
  FETCH_UPLOADS_SUCCESS} from 'app/actions';

export interface IAppState {

  uploads: any;

  user: any;

}

export const INITIAL_STATE: IAppState = {


  uploads: [],

  user: {}

}


export function rootReducer(state: IAppState, action): IAppState {

  switch (action.type) {


    case FETCH_UPLOADS_SUCCESS:

    console.log('FETCH_UPLOADS_SUCCESS');

    state.uploads = action.uploads;

    return tassign (state, {
      uploads: state.uploads
    });

    case FETCH_USER_SUCCESS:

    console.log('Store FETCH_USER_SUCCESS');

    state.user = action.user;

    return tassign (state, {
      user: state.user
    });


  }
  return state;
}
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'ng-footer',
  templateUrl: './ng-footer.component.html',
  styleUrls: ['./ng-footer.component.scss']
})
export class NgFooterComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UploadButtonComponent } from 'shared/upload/components/upload-button/upload-button.component';
import { UploadDialogComponent } from 'shared/upload/components/upload-dialog/upload-dialog.component';
import { UploadsService } from 'shared/upload/services/uploads.service';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule } from '@angular/material/dialog';
import { MatListModule } from '@angular/material/list';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClientModule } from '@angular/common/http';


@NgModule({
  imports: [
    CommonModule,
    MatButtonModule,
    MatDialogModule,
    MatListModule,
    HttpClientModule,
    BrowserAnimationsModule,
    MatProgressBarModule
  ],
  declarations: [
    UploadButtonComponent,
    UploadDialogComponent
  ],
  exports: [
    UploadButtonComponent
  ],
  entryComponents: [
    UploadDialogComponent
  ],
  providers: [
    UploadsService
  ]
})
export class UploadModule { }

import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { UploadDialogComponent } from 'shared/upload/components/upload-dialog/upload-dialog.component';
import { UploadsService } from 'shared/upload/services/uploads.service';

@Component({
  selector: 'upload-button',
  templateUrl: './upload-button.component.html',
  styleUrls: ['./upload-button.component.scss']
})
export class UploadButtonComponent {

  constructor(public dialog: MatDialog, public uploadService: UploadsService) {}

  public openUploadDialog() {
    let dialogRef = this.dialog.open(UploadDialogComponent, { width: '50%', height: '50%' });
  }

}

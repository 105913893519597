import { NgModule } from '@angular/core';
import { HomeComponent } from './components/home/home.component';
import { SharedModule } from 'shared/shared.module';
import { RouterModule } from '@angular/router';
import { NgFooterComponent } from 'core/components/ng-footer/ng-footer.component';
import { NgNavbarComponent } from 'core/components/ng-navbar/ng-navbar.component';
import { NgUserMenuComponent } from 'core/components/ng-user-menu/ng-user-menu.component';



@NgModule({
  declarations: [
    HomeComponent,
    NgFooterComponent,
    NgNavbarComponent,
    NgUserMenuComponent
  ],
  imports: [
    SharedModule,

    
    RouterModule.forChild([

      // home page
      { path: '', component: HomeComponent },


    ])
  ],
  exports: [

    NgFooterComponent,
    NgNavbarComponent,
    NgUserMenuComponent
  ]
})
export class CoreModule { }

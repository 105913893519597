<ng-template #anonymousUser>
  <a mat-button matTooltip="Login/Signup" routerLink="/login">
    <mat-icon>account_circle</mat-icon>
  </a>
</ng-template>

<button
  mat-button
  *ngIf="user && user.username; else anonymousUser"
  matTooltip="User Menu"
  [matMenuTriggerFor]="userMenu">
  {{ user.username }}
</button>

<mat-menu aria-label="User Menu Dropdown Panel" #userMenu="matMenu">

    <ng-template matMenuContent>
  

      <a mat-menu-item [routerLink]="['/uploads']">
        <mat-icon>cloud_upload</mat-icon>
        My Uploads
      </a>
      
      <hr>
      <button mat-menu-item (click)="logout()">
        <mat-icon>power_settings_new</mat-icon>
        Log Out
      </button>
    </ng-template>
  </mat-menu>
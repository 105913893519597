<h1>IRT World</h1>
<h2>Augmenting Reality with Infrared Thermography</h2>

<div
  fxLayout="column"
  fxLayout.xs="column"
  fxLayoutAlign="space-around center"
  fxLayoutGap="20px"
  fxLayoutGap.xs="10px">

  <div
    fxLayout="row wrap"
    fxLayout.xs="column"
    fxLayoutAlign="space-around center"
    fxLayoutGap="5px"
    fxLayoutGap.xs="2px">
  
    <div
      fxFlex.xl="48"
      fxFlex.lg="58"
      fxFlex.md="68"
      fxFlex.sm="78"
      fxFlex.xs="98">

      <mat-card>
        <div *ngIf="this.ar_view">
          <mat-card-content>
            <img mat-card-image width="480" src="{{ this.ar_src }}" alt="RGB Diagnostic Portal">
          </mat-card-content>
        </div>
      </mat-card>
    </div>
  </div>

  <div
    fxLayout="row wrap"
    fxLayout.xs="column"
    fxLayoutAlign="space-around center"
    fxLayoutGap="5px"
    fxLayoutGap.xs="2px">
  
    <div
      fxFlex.xl="48"
      fxFlex.lg="58"
      fxFlex.md="68"
      fxFlex.sm="78"
      fxFlex.xs="98">

      <mat-card>
        <div *ngIf="this.rgb_view">
          <mat-card-content>
            <img mat-card-image width="480" src="{{ this.rgb_src }}" alt="RGB Diagnostic Portal">
          </mat-card-content>
        </div>
      </mat-card>
    </div>
  </div>

  <div
    fxLayout="row wrap"
    fxLayout.xs="column"
    fxLayoutAlign="space-around center"
    fxLayoutGap="5px"
    fxLayoutGap.xs="2px">

    <div
      fxFlex.xl="48"
      fxFlex.lg="58"
      fxFlex.md="68"
      fxFlex.sm="78"
      fxFlex.xs="98">
      <mat-card>
        <div *ngIf="this.irt_view">
          <mat-card-content>
            <img mat-card-image width="480" src="{{ this.irt_src }}" alt="IRT Diagnostic Portal">
          </mat-card-content>
        </div>
      </mat-card>

    </div>
  </div>
        
  <mat-card>

    <mat-card-footer>
      <mat-checkbox class="ar-card-margin" [(ngModel)]="ar_view">AR</mat-checkbox>
      <mat-checkbox class="ar-card-margin" [(ngModel)]="rgb_view">RGB</mat-checkbox>
      <mat-checkbox class="ar-card-margin" [(ngModel)]="irt_view">IR</mat-checkbox>
    </mat-card-footer>
  </mat-card>

  <p>Stream URL: rtmp://irtworld.com/rgb/{{ this.userId }}</p>

</div>


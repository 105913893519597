
<h1>Sign Up</h1>

<form class="standard-form" #f="ngForm" (ngSubmit)="signup(f.value)">

  <mat-form-field>
    <input 
      matInput
      ngModel
      #email="ngModel"
      name="email"
      id="email"
      placeholder="Email Address"
      type="email"
      required
      autofocus>
      <span matPrefix>
        <mat-icon>email</mat-icon>
      </span>
      <mat-hint>Type a fake email address for testing. This site is still under development.</mat-hint>
      <mat-error *ngIf="email.invalid && email.errors.required">
        Your email address is required.
      </mat-error>
      <mat-error *ngIf="email.invalid">
        Please type a valid email address.
      </mat-error>
  </mat-form-field>

  <mat-form-field>
    <input 
      matInput
      ngModel
      #username="ngModel"
      name="username"
      id="username"
      placeholder="Username"
      type="text"
      required>
      <span matPrefix>
        <mat-icon>perm_identity</mat-icon>      
      </span>
      <mat-hint>Type a fake username for testing. A clever username helps to protect your online privacy.</mat-hint>      
      <mat-error *ngIf="username.invalid && username.errors.required">
        The username field is required.
      </mat-error>
  </mat-form-field>

  <mat-form-field>
    <input
    matInput
    ngModel
    #password="ngModel"
    name="password"
    id="password"
    placeholder="Password"
    type="password"
    required>
    <span matPrefix>
      <mat-icon>lock_outline</mat-icon>      
    </span>
    <mat-hint>Type a password for testing. A strong password helps to protect your online privacy.</mat-hint>    
  </mat-form-field>



  <mat-form-field>
    <input 
      matInput
      ngModel
      #birthday="ngModel"
      name="birthday"
      id="birthday"
      [matDatepicker]="birthday"
      placeholder="Your birthday"
      date
      required>
    <mat-hint>MM/DD/YYYY</mat-hint>
    <mat-datepicker-toggle matSuffix [for]="birthday"></mat-datepicker-toggle>
    <mat-datepicker #birthday></mat-datepicker>
    <mat-error *ngIf="birthday.invalid && birthday.errors.required">
      The birthday field is required.
    </mat-error>
  </mat-form-field>


  <button mat-raised-button color="primary" type="submit">Sign Up</button>
  
</form>

<mat-list role="list">
  <mat-list-item *ngFor="let m of messages">{{m}}</mat-list-item>
</mat-list>

import { NgRedux } from '@angular-redux/store';
import { Component, Input, AfterViewInit, ViewChild } from '@angular/core';
import { IAppState } from 'app/store';
import { Subscription } from 'rxjs';
import { Upload } from 'shared/models/upload';
import { Feathers } from 'shared/services/feathers.service';
import { LiveAuthService } from 'shared/services/live-auth.service';
import { UploadService } from 'shared/services/upload.service';

@Component({
  selector: 'upload-card',
  templateUrl: './upload-card.component.html',
  styleUrls: ['./upload-card.component.scss']
})
export class UploadCardComponent implements AfterViewInit {
  
  @Input('upload') upload: Upload;

  urlString: any;

  src: any;
  user: any;
  userId: any;
  userSubscription: Subscription;

  constructor(
    private feathers: Feathers,
    private liveAuth: LiveAuthService,
    private service: UploadService,
    private ngRedux: NgRedux<IAppState>) {}

    async ngAfterViewInit() {

      await this.populateUser();
  
      console.log('live auth request!!');
  
    }
  
    private async populateUser() {
  
      this.userSubscription = (await this.ngRedux.select('user'))
        .subscribe( res => {
  
          this.user = res;
  
          if (this.user && this.user._id) {
  
            this.userId = this.user._id
  
    
            console.log('found user! ', res);
    
            this.RequestToken();
          }
        });
    }

    private RequestToken() {

      console.log('Requesting Live Stream Token');
  
      this.liveAuth.create({ text: "Authenticate me."})
        .then( res => {
  
          console.log('live auth result', res);
          let token: string = res.accessToken.toString();
          this.src = 'https://irtdoc.dablabllc.com/assets/images/';
          this.src += this.userId;
          this.src += '/';
          this.src += this.upload.imageUrl;
          this.src += '?Authorization=Bearer ';
          this.src += token;
          this.src = this.src.toString();
  
      });
    }


  delete() {
    this.service.remove(this.upload._id);

  }
}

import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpRequest, HttpEventType, HttpResponse } from '@angular/common/http';
import { Subject } from 'rxjs';
import { Observable } from 'rxjs/Observable';
import { Feathers } from 'shared/services/feathers.service';
import { AuthService } from 'shared/services/auth.service';

const url = 'https://irtdoc.dablabllc.com/upload';

@Injectable({
  providedIn: 'root'
})
export class UploadsService {

  uploads: string[] = [];

  constructor(
    private http: HttpClient,
    private feathers: Feathers,
    private authService: AuthService) {}


  public upload(files: Set<File>): {[key:string]:Observable<number>} {
    // this will be the our resulting map
    let status = {};

    let token: any = this.feathers.client.passport.getJWT();

    console.log('token', token);

    token = 'Bearer ' + token.__zone_symbol__value;
    token = token.toString();
    console.log('token', token);

    let headers = new HttpHeaders({
      'Authorization' : token
    });
    
    files.forEach(file => {

      // create a new multipart-form for every file
      let formData: FormData = new FormData();
      formData.append('file', file, file.name);

      console.log('uriFile', file);

      // create a http-post request and pass the form
      // tell it to report the upload progress
      let req = new HttpRequest('POST', url, formData, {
        headers: headers,
        reportProgress: true
      });

      console.log('req', req);

      // create a new progress-subject for every file
      let progress = new Subject<number>();

      // send the http-request and subscribe for progress-updates
      this.http.request(req).subscribe(event => {
        if (event.type === HttpEventType.UploadProgress) {

          // calculate the progress percentage
          let percentDone = Math.round(100 * event.loaded / event.total);


          // pass the percentage into the progress-stream
          progress.next(percentDone);
        } else if (event instanceof HttpResponse) {

          console.log('event', event);

          let upload = event.body['id'];

          console.log('upload', upload);

          this.uploads.push(upload);

          console.log('uploads', this.uploads);


          // Close the progress-stream if we get an answer form the API
          // The upload is complete
          progress.complete();

          // loadMyResources Uploads called by authuser()
          //this.authService.authUser();
        }
      });

      // Save every progress-observable in a map of all observables
      status[file.name] = {
        progress: progress.asObservable()
      };
    });

    console.log('status', status);

    // return the map of progress.observables
    return status;
  }
}
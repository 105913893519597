import { Component, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { IAppState } from 'app/store';
import { NgRedux } from '@angular-redux/store';
import { LiveAuthService } from 'shared/services/live-auth.service';

@Component({
  selector: 'home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {

  user: any;
  userId: any;

  ar_src: any;
  rgb_src: any;
  irt_src: any;

  ar_view: Boolean;
  rgb_view: Boolean;
  irt_view: Boolean;
  
  userSubscription: Subscription;

  constructor(
    private liveAuth: LiveAuthService,
    private ngRedux: NgRedux<IAppState>) {}

  async ngOnInit() {

    this.ar_view = true;
    this.rgb_view = true;
    this.irt_view = true;
    await this.populateUser();

    console.log('live auth request!!');

  }

  
  private async populateUser() {

    this.userSubscription = (await this.ngRedux.select('user'))
      .subscribe( res => {

        this.user = res;

        if (this.user && this.user._id) {

          this.userId = this.user._id

  
          console.log('found user! ', res);
  
          this.authenticateStreams();
        }

      });
  }

  private authenticateStreams() {

    console.log('Requesting Live Stream Token');

    this.liveAuth.create({ text: "Authenticate me."})
      .then( res => {

        console.log('live auth result', res);
        let token: string = res.accessToken.toString();

        this.ar_src = 'https://irtworld.com/ar_feed/'
        this.ar_src += this.userId.toString();
        this.ar_src += '?Authorization=Bearer '+token;

        this.rgb_src = 'https://irtworld.com/rgb_feed/'
        this.rgb_src += this.userId.toString();
        this.rgb_src += '?Authorization=Bearer '+token;

        this.irt_src = 'https://irtworld.com/irt_feed/'
        this.irt_src += this.userId.toString();
        this.irt_src += '?Authorization=Bearer '+token;

    });
  }

  ngOnDestroy() {

    this.userSubscription.unsubscribe();
  }
}

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MaterialModule } from 'shared/material/material.module';
import { UploadModule } from 'shared/upload/upload.module';
import { FormsModule } from '@angular/forms';

import { AuthService } from 'shared/services/auth.service';
import { Feathers } from 'shared/services/feathers.service';
import { UploadService } from 'shared/services/upload.service';

import { NgReduxModule } from '@angular-redux/store';
import { UploadCardComponent } from './components/upload-card/upload-card.component';
import { TestCardComponent } from './components/test-card/test-card.component';
import { ResultCardComponent } from './components/result-card/result-card.component';
import { LiveAuthService } from './services/live-auth.service';


@NgModule({
  declarations: [
    UploadCardComponent,
    TestCardComponent,
    ResultCardComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    MaterialModule,
    UploadModule,
    NgReduxModule,
    BrowserModule,
    BrowserAnimationsModule,
    RouterModule.forChild([])

  ],
  exports: [
    UploadCardComponent,
    CommonModule,
    FormsModule,
    MaterialModule,
    UploadModule,
    NgReduxModule
  ],
  providers: [
    AuthService,
    Feathers,
    LiveAuthService,
    UploadService
  ]
})

export class SharedModule {}

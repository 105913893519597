
<mat-toolbar color="primary">
  <a
    mat-icon-button
    matTooltip="Navigate Home"
    aria-label="Navigate Home Icon"
    [routerLink]="'/'">
    <img [src]="'/favicon.ico'" alt="Home of IRT Diagnostics">
  </a>

  <div class="fill-remaining-space"></div>


  <ng-user-menu [user]="user"></ng-user-menu>
  
</mat-toolbar>
  
<mat-toolbar color="primary">


  <mat-toolbar-row>

    <p>Powered by DAB LAB LLC<mat-icon>copyright</mat-icon>2016</p>

    <span class="fill-remaining-space"></span>

    <a mat-icon-button href="/">
      <img src="favicon.ico" alt="DAB LAB LLC Logo">
    </a>
  </mat-toolbar-row>

</mat-toolbar>

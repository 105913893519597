import { Component, Input } from '@angular/core';
import { User } from 'shared/models/user';
import { AuthService } from 'shared/services/auth.service';

@Component({
  selector: 'ng-user-menu',
  templateUrl: './ng-user-menu.component.html',
  styleUrls: ['./ng-user-menu.component.scss']
})
export class NgUserMenuComponent {

  @Input('user') user: User;

  constructor(private auth: AuthService) {}

  logout() {
    this.auth.logOut();
    this.user = <User>{};
  }

}

import { Injectable } from '@angular/core';
import { Feathers } from './feathers.service';

@Injectable({
  providedIn: 'root'
})
export class LiveAuthService {

  servicePath: string = 'live-auths';

  constructor(private feathers: Feathers) { }

  
  find() {
    return this.feathers
      .service(this.servicePath)
      .watch()
      .find()
  }

  get(resourceId: any) {
    return this.feathers
      .service(this.servicePath)
      .watch()
      .get(resourceId)
  }

  patch(resourceId: string, resource: any) {
    return this.feathers
      .service(this.servicePath)
      .patch(resourceId, resource);
  }

  update(resourceId: string, resource: any) {
    return this.feathers
      .service(this.servicePath)
      .update(resourceId, resource);
  }

  create(resource: any) {
    return this.feathers
      .service(this.servicePath)
      .create(resource);
  }
}

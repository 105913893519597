import { NgRedux } from '@angular-redux/store';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { IAppState } from 'app/store';
import { Subscription } from 'rxjs/Subscription';


@Component({
  selector: 'ng-navbar',
  templateUrl: './ng-navbar.component.html',
  styleUrls: ['./ng-navbar.component.scss']
})

export class NgNavbarComponent implements OnInit, OnDestroy {

  user: any = {};

  userSubscription: Subscription;

  constructor(private ngRedux: NgRedux<IAppState>) {}

  async ngOnInit() {
    await this.populateUser();

  }

  private async populateUser() {

    this.userSubscription = (await this.ngRedux.select('user'))
      .subscribe( res => {
        this.user = res;
      });
  }



  ngOnDestroy() {
    this.userSubscription.unsubscribe();
  }
}
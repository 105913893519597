<div class="outer">
  <div class="inner">
    <a href="#maincontent">Skip Navigation</a> 
  </div>
</div>

<div class="container">
  
  <ng-navbar class="navbar"></ng-navbar>

 
  <div class="main">

    <a name="maincontent" id="maincontent"></a>

    <router-outlet #outlet="outlet"></router-outlet>
      
  </div>
  
  <ng-footer class="footer"></ng-footer>
</div>
import { NgModule } from '@angular/core';
import { SharedModule } from 'shared/shared.module';
import { RouterModule } from '@angular/router';
import { NoAccessComponent } from 'auth/components/no-access/no-access.component';

import { LoginComponent } from 'auth/components/login/login.component';
import { SignupComponent } from 'auth/components/signup/signup.component';

import { LoginFormComponent } from 'auth/components/login-form/login-form.component';
import { SignupFormComponent } from 'auth/components/signup-form/signup-form.component';

@NgModule({
  imports: [
    SharedModule,
    RouterModule.forChild([
      // authentication pages
      { path: 'no-access', component: NoAccessComponent },

      { path: 'login', component: LoginComponent },
      
      { path: 'signup', component: SignupComponent }
    ])
  ],
  declarations: [
    NoAccessComponent,
    LoginComponent,
    SignupComponent,
    LoginFormComponent,
    SignupFormComponent
  ]
})
export class AuthModule { }
